@import '~@/@core/preset/preset/variables.scss';
$body-font-family: 'Neue Haas Grotesk Text Pro', sans-serif;
$shades: (
  'black': #000,
  'white': #fff,
  'transparent': transparent,
);
// Override Vuetify Variables & Template variables
$primary-shade--light: #505a68;
$primary-shade--dark: #e7e3fc;
$shadow-color--light: $primary-shade--light;
$shadow-color--dark: #131120;
/* Colors */
$light-bg: #fff;
$light-grey: #f3f3f3;
$text-blue: #2830ce;
$text-grey: #898989;
$text-black: #000000;

/* Radius */
$default-border-radius: 3px;

/* Border */
$default-border-solid: 2px solid $light-grey;

/* Vendor */
$material-light: map-deep-merge(
    $material-light,
    (
      'background': $light-bg,
    )
);


